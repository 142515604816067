/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, YouTube } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Jazz"} description={"Jazz"}>
        <Column className="pb--30 pt--30" name={"x7iwilye80c"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"The Jazz Garden<br>"}>
              </Title>

              <Text className="text-box" content={"Album plné postmoderního jazzu<br>Album full of postmodern jazz<br>"}>
              </Text>

              <Button className="btn-box" href={"/thejazzgarden"} content={"The Jazz Garden<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" url={"https://otto-o.com/"} href={"https://otto-o.com/"} target={null} content={"Home"}>
              </Button>

              <Image src={"https://cdn.swbpg.com/t/2002/9755a2540a55486a838325ddb9a4e8c6_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":443}} srcSet={"https://cdn.swbpg.com/t/2002/9755a2540a55486a838325ddb9a4e8c6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/9755a2540a55486a838325ddb9a4e8c6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2002/9755a2540a55486a838325ddb9a4e8c6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2002/9755a2540a55486a838325ddb9a4e8c6_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"xf3yhafa4ps"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<br><br><br><br>Neon Mandala"}>
              </Title>

              <Text className="text-box" content={"Electronic jazz<br>"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/neonmandala"} content={"Neon Mandala"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/2002/c3f11d7fe7694b169861572c27a639e2_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":440}} srcSet={"https://cdn.swbpg.com/t/2002/c3f11d7fe7694b169861572c27a639e2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/c3f11d7fe7694b169861572c27a639e2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2002/c3f11d7fe7694b169861572c27a639e2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2002/c3f11d7fe7694b169861572c27a639e2_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"btg3td40c1o"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"MB Jazz"}>
              </Title>

              <Text className="text-box" content={"Z brusu nový big band napsaný pro mého kamaráda a saxofonistu, Marka Bubleho<br>A fresh new big band written for my friend and saxophonist Marek Buble"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"H5HUOsOJVzw"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"90e4n47wdwf"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Blue Hats<br>"}>
              </Title>

              <Text className="text-box" content={"Písně zkomponované pro jazzové kvarteto Blue Hats<br>Songs made for jazz Q Blue Hats<br>"}>
              </Text>

              <Button className="btn-box" href={"/bluehats"} content={"Blue Hats<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2002/8c75e1466dbf4664a277da5c90ae6780_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":443}} srcSet={"https://cdn.swbpg.com/t/2002/8c75e1466dbf4664a277da5c90ae6780_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/8c75e1466dbf4664a277da5c90ae6780_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ddkgf0yy6bh"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Summer Samba"}>
              </Title>

              <Text className="text-box" content={"Swingová úprava známého standartu pro klavír a kontrabas<br>Swingy cover for piano and double bass<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"zN-GqeJNwXo"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"phovi1rfz9p"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 mb--80 mt--80" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"ISC 2020 semifinalist"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":668}} content={"Do semifinále soutěže Internatoinal Songwriting Competition byla vybrána skladba Goddes Eywa z alba The Jazz Garden. Goddess Eywa oslovila porotu stejně jako dalších&nbsp;9% skladeb z celkem 2600 skladeb, které porota obdržela<br>International Songwriting Competition have chosen us to semifinal round. The song Goddess Eywa from album The Jazz Garden has taken place in semifinale as other 9% songs from 2600 they received to judge"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/2002/bd28fa03c8ba42459c90fa8861bdedcc_s=660x_.png"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":178}} srcSet={"https://cdn.swbpg.com/t/2002/bd28fa03c8ba42459c90fa8861bdedcc_s=350x_.png 350w, https://cdn.swbpg.com/t/2002/bd28fa03c8ba42459c90fa8861bdedcc_s=660x_.png 660w, https://cdn.swbpg.com/t/2002/bd28fa03c8ba42459c90fa8861bdedcc_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}